/**
 * KKH Unfallfragebogen
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: #{placeholderVersion}#
 * Contact: online@kkh.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PublicTransportBvs } from './public-transport';
import { RoadUserBvs } from './road-user';

/**
 * Verkehrsunfall
 */
export interface TrafficAccidentBvs {
  /**
   * beteiligt als
   */
  involvedAs: TrafficAccidentBvs.InvolvedAsBvs;
  /**
   * Fahrzeughalter vorhanden
   */
  vehicleKeeperExists: boolean;
  /**
   * Eigentuemer
   */
  owner: boolean;
  /**
   * Daten des Fahrzeughalters
   */
  vehicleKeeperDetails?: string;
  /**
   * Kennzeichen
   */
  registrationNumber?: string;
  /**
   * Haftpflichtversicherung
   */
  insuranceName?: string;
  /**
   * Verkehrsmittel
   */
  transport?: string;
  roadUser?: RoadUserBvs;
  publicTransport?: PublicTransportBvs;
}
export namespace TrafficAccidentBvs {
  export type InvolvedAsBvs =
    | 'driver'
    | 'car_passenger'
    | 'public_transport_passenger'
    | 'bicyclist'
    | 'pedestrian'
    | 'driver_of_another_transport'
    | 'passenger_of_another_transport';
  export const InvolvedAsBvs = {
    Driver: 'driver' as InvolvedAsBvs,
    CarPassenger: 'car_passenger' as InvolvedAsBvs,
    PublicTransportPassenger: 'public_transport_passenger' as InvolvedAsBvs,
    Bicyclist: 'bicyclist' as InvolvedAsBvs,
    Pedestrian: 'pedestrian' as InvolvedAsBvs,
    DriverOfAnotherTransport: 'driver_of_another_transport' as InvolvedAsBvs,
    PassengerOfAnotherTransport: 'passenger_of_another_transport' as InvolvedAsBvs,
  };
}

/**
 * KKH Unfallfragebogen
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: #{placeholderVersion}#
 * Contact: online@kkh.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ConditionalPropertyBvs } from './conditional-property';

/**
 * Oeffentliches Verkehrsmittel
 */
export interface PublicTransportBvs {
  /**
   * Art des Verkehrsmittels
   */
  publicTransportType: PublicTransportBvs.PublicTransportTypeBvs;
  /**
   * Linie bzw. Verbindung
   */
  route: string;
  /**
   * Richtung
   */
  direction: string;
  transportCompany: ConditionalPropertyBvs;
}
export namespace PublicTransportBvs {
  export type PublicTransportTypeBvs = 'train' | 'tram' | 'bus';
  export const PublicTransportTypeBvs = {
    Train: 'train' as PublicTransportTypeBvs,
    Tram: 'tram' as PublicTransportTypeBvs,
    Bus: 'bus' as PublicTransportTypeBvs,
  };
}

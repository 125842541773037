/**
 * KKH Unfallfragebogen
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: #{placeholderVersion}#
 * Contact: online@kkh.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { EquestrianSportBvs } from './equestrian-sport';
import { PrivateSportBvs } from './private-sport';
import { NameCityBvs } from './name-city';

/**
 * Sportunfall
 */
export interface SportAccidentBvs {
  /**
   * Art des Sportunfalls
   */
  sportAccidentType: SportAccidentBvs.SportAccidentTypeBvs;
  /**
   * Ihre Funktion
   */
  sportAccidentFunction?: SportAccidentBvs.SportAccidentFunctionBvs;
  /**
   * hohes Entgelt Sportfunktion vorhanden
   */
  highPaymentSport?: boolean;
  /**
   * eigenes Reittier
   */
  ownHorse?: boolean;
  company?: NameCityBvs;
  equestrianSport?: EquestrianSportBvs;
  privateSport?: PrivateSportBvs;
}
export namespace SportAccidentBvs {
  export type SportAccidentTypeBvs = 'private' | 'company_or_school' | 'equitation' | 'club';
  export const SportAccidentTypeBvs = {
    Private: 'private' as SportAccidentTypeBvs,
    CompanyOrSchool: 'company_or_school' as SportAccidentTypeBvs,
    Equitation: 'equitation' as SportAccidentTypeBvs,
    Club: 'club' as SportAccidentTypeBvs,
  };
  export type SportAccidentFunctionBvs = 'trainer' | 'player' | 'other';
  export const SportAccidentFunctionBvs = {
    Trainer: 'trainer' as SportAccidentFunctionBvs,
    Player: 'player' as SportAccidentFunctionBvs,
    Other: 'other' as SportAccidentFunctionBvs,
  };
}

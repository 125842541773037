/**
 * KKH Unfallfragebogen
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: #{placeholderVersion}#
 * Contact: online@kkh.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ConditionalPropertyBitgo } from './conditional-property';

/**
 * Reitsport
 */
export interface EquestrianSportBitgo {
  /**
   * hat Reitberuf
   */
  ridingProfession: EquestrianSportBitgo.RidingProfessionBitgo;
  /**
   * hat Pferdebetreuungsauftrag
   */
  horseCare: boolean;
  /**
   * Reittaetigkeit vorhanden
   */
  ridingActivity: boolean;
  /**
   * Sonstige Taetigkeit
   */
  otherActivity?: string;
  /**
   * Zeit für sonstige Taetigkeit
   */
  timeOtherActivity?: string;
  returnService?: ConditionalPropertyBitgo;
  /**
   * hat eine ausreichende Reitfaehigkeit
   */
  sufficientRidingAbility: boolean;
  /**
   * ein auffaelliges Pferd
   */
  noticeableHorse: boolean;
  /**
   * ein Reiten auf eigene Gefahr
   */
  ridingAtOwnRisk: boolean;
  /**
   * ein Reit-Versicherungstyp
   */
  insuranceType: EquestrianSportBitgo.InsuranceTypeBitgo;
  horseOwner: ConditionalPropertyBitgo;
  liabilityInsurance: ConditionalPropertyBitgo;
}
export namespace EquestrianSportBitgo {
  export type RidingProfessionBitgo = 'yes' | 'no' | 'unknown';
  export const RidingProfessionBitgo = {
    Yes: 'yes' as RidingProfessionBitgo,
    No: 'no' as RidingProfessionBitgo,
    Unknown: 'unknown' as RidingProfessionBitgo,
  };
  export type InsuranceTypeBitgo =
    | 'liability_exclusion'
    | 'participation'
    | 'liability_exclusion_with_participation'
    | 'no_liability_exclusion_or_participation';
  export const InsuranceTypeBitgo = {
    LiabilityExclusion: 'liability_exclusion' as InsuranceTypeBitgo,
    Participation: 'participation' as InsuranceTypeBitgo,
    LiabilityExclusionWithParticipation: 'liability_exclusion_with_participation' as InsuranceTypeBitgo,
    NoLiabilityExclusionOrParticipation: 'no_liability_exclusion_or_participation' as InsuranceTypeBitgo,
  };
}

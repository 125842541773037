/**
 * KKH Unfallfragebogen
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: #{placeholderVersion}#
 * Contact: online@kkh.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AccidentCauseBvs } from './accident-cause';

/**
 * Fremdes Grundstueck
 */
export interface PrivateLandBvs {
  /**
   * Unfallort
   */
  accidentPlace: PrivateLandBvs.AccidentPlaceBvs;
  /**
   * Details zu Unfallort
   */
  detailsAccidentPlace?: string;
  /**
   * Eigentuemer
   */
  owner: boolean;
  /**
   * Gehwege vorhanden
   */
  footways: boolean;
  /**
   * Winterglaette vorhanden
   */
  winterSmoothness: boolean;
  /**
   * Witterungen
   */
  weatherConditions?: PrivateLandBvs.WeatherConditionsBvs;
  /**
   * Glaette am Vortag vorhanden
   */
  smoothnessYesterday?: PrivateLandBvs.SmoothnessYesterdayBvs;
  /**
   * Raeumung vorhanden
   */
  evacuation?: PrivateLandBvs.EvacuationBvs;
  /**
   * Streuung vorhanden
   */
  scattering?: PrivateLandBvs.ScatteringBvs;
  /**
   * Hinweisschild Winterdienst vorhanden
   */
  informationSignWinterService: boolean;
  /**
   * Hinweisschilder vorhanden
   */
  informationSigns: boolean;
  /**
   * Fotos Unfallstelle vorhanden
   */
  picturesSceneOfAccident: boolean;
  /**
   * Eigentuemer Unfallort vorhanden
   */
  ownerAccidentPlace: boolean;
  /**
   * Sturzursachen
   */
  accidentCauses?: Array<AccidentCauseBvs>;
  /**
   * Details zu Sturzursachen
   */
  detailsAccidentCause?: string;
  /**
   * Haftpflichtversicherung des Eigentümers
   */
  ownerLiabilityInsurance?: string;
  /**
   * Name des Eigentümers
   */
  ownerName?: string;
}
export namespace PrivateLandBvs {
  export type AccidentPlaceBvs = 'stairs' | 'roadway' | 'footway' | 'parking' | 'other';
  export const AccidentPlaceBvs = {
    Stairs: 'stairs' as AccidentPlaceBvs,
    Roadway: 'roadway' as AccidentPlaceBvs,
    Footway: 'footway' as AccidentPlaceBvs,
    Parking: 'parking' as AccidentPlaceBvs,
    Other: 'other' as AccidentPlaceBvs,
  };
  export type WeatherConditionsBvs = 'snowfall' | 'glazeice';
  export const WeatherConditionsBvs = {
    Snowfall: 'snowfall' as WeatherConditionsBvs,
    Glazeice: 'glazeice' as WeatherConditionsBvs,
  };
  export type SmoothnessYesterdayBvs = 'yes' | 'no' | 'unknown';
  export const SmoothnessYesterdayBvs = {
    Yes: 'yes' as SmoothnessYesterdayBvs,
    No: 'no' as SmoothnessYesterdayBvs,
    Unknown: 'unknown' as SmoothnessYesterdayBvs,
  };
  export type EvacuationBvs = 'yes' | 'no' | 'partially';
  export const EvacuationBvs = {
    Yes: 'yes' as EvacuationBvs,
    No: 'no' as EvacuationBvs,
    Partially: 'partially' as EvacuationBvs,
  };
  export type ScatteringBvs = 'yes' | 'no' | 'partially';
  export const ScatteringBvs = {
    Yes: 'yes' as ScatteringBvs,
    No: 'no' as ScatteringBvs,
    Partially: 'partially' as ScatteringBvs,
  };
}

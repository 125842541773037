import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { BvsConfiguration } from './configuration';
import { HttpClient } from '@angular/common/http';

@NgModule({
  imports: [],
  declarations: [],
  exports: [],
  providers: [],
})
export class BvsApiModule {
  public static forRoot(configurationFactory: () => BvsConfiguration): ModuleWithProviders<BvsApiModule> {
    return {
      ngModule: BvsApiModule,
      providers: [{ provide: BvsConfiguration, useFactory: configurationFactory }],
    };
  }

  constructor(@Optional() @SkipSelf() parentModule: BvsApiModule, @Optional() http: HttpClient) {
    if (parentModule) {
      throw new Error('BvsApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error(
        'You need to import the HttpClientModule in your AppModule! \n' +
          'See also https://github.com/angular/angular/issues/20575',
      );
    }
  }
}

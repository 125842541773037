/**
 * KKH Unfallfragebogen
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: #{placeholderVersion}#
 * Contact: online@kkh.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Verantwortliche Person
 */
export interface ResponsiblePersonBitgo {
  /**
   * Angaben zur verantwortlichen Person
   */
  personDetails: string;
  /**
   * Angaben zum Verwandtschaftsgrad
   */
  relationToPerson: ResponsiblePersonBitgo.RelationToPersonBitgo;
  /**
   * verantwortliche Personen-Gemeinschaft vorhanden
   */
  groupOfPersons: boolean;
}
export namespace ResponsiblePersonBitgo {
  export type RelationToPersonBitgo = 'related' | 'cohabilitation' | 'other';
  export const RelationToPersonBitgo = {
    Related: 'related' as RelationToPersonBitgo,
    Cohabilitation: 'cohabilitation' as RelationToPersonBitgo,
    Other: 'other' as RelationToPersonBitgo,
  };
}

/**
 * KKH Unfallfragebogen
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: #{placeholderVersion}#
 * Contact: online@kkh.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Fremdes Grundstueck
 */
export interface PrivateLandBitgo {
  /**
   * Unfallort
   */
  accidentPlace: PrivateLandBitgo.AccidentPlaceBitgo;
  /**
   * Details zu Unfallort
   */
  detailsAccidentPlace?: string;
  /**
   * Eigentuemer
   */
  owner: boolean;
  /**
   * Gehwege vorhanden
   */
  footways: boolean;
  /**
   * Winterglaette vorhanden
   */
  winterSmoothness: boolean;
  /**
   * Witterungen
   */
  weatherConditions?: PrivateLandBitgo.WeatherConditionsBitgo;
  /**
   * Glaette am Vortag vorhanden
   */
  smoothnessYesterday?: PrivateLandBitgo.SmoothnessYesterdayBitgo;
  /**
   * Raeumung vorhanden
   */
  evacuation: PrivateLandBitgo.EvacuationBitgo;
  /**
   * Streuung vorhanden
   */
  scattering: PrivateLandBitgo.ScatteringBitgo;
  /**
   * Hinweisschild Winterdienst vorhanden
   */
  informationSignWinterService: boolean;
  /**
   * Hinweisschilder vorhanden
   */
  informationSigns: boolean;
  /**
   * Fotos Unfallstelle vorhanden
   */
  picturesSceneOfAccident: boolean;
  /**
   * Eigentuemer Unfallort vorhanden
   */
  ownerAccidentPlace: boolean;
  /**
   * Eigentuemer informiert
   */
  ownerInformed: boolean;
  /**
   * Sturzursachen
   */
  accidentCause: PrivateLandBitgo.AccidentCauseBitgo;
  /**
   * Details zu Sturzursachen
   */
  detailsAccidentCause?: string;
  /**
   * Haftpflichtversicherung des Eigentümers
   */
  ownerLiabilityInsurance?: string;
  /**
   * Name des Eigentümers
   */
  ownerName?: string;
  /**
   * Datum Eigentuemer informiert
   */
  ownerInformedDate: string;
}
export namespace PrivateLandBitgo {
  export type AccidentPlaceBitgo = 'stairs' | 'roadway' | 'footway' | 'parking' | 'other';
  export const AccidentPlaceBitgo = {
    Stairs: 'stairs' as AccidentPlaceBitgo,
    Roadway: 'roadway' as AccidentPlaceBitgo,
    Footway: 'footway' as AccidentPlaceBitgo,
    Parking: 'parking' as AccidentPlaceBitgo,
    Other: 'other' as AccidentPlaceBitgo,
  };
  export type WeatherConditionsBitgo = 'snowfall' | 'glazeice';
  export const WeatherConditionsBitgo = {
    Snowfall: 'snowfall' as WeatherConditionsBitgo,
    Glazeice: 'glazeice' as WeatherConditionsBitgo,
  };
  export type SmoothnessYesterdayBitgo = 'yes' | 'no' | 'unknown';
  export const SmoothnessYesterdayBitgo = {
    Yes: 'yes' as SmoothnessYesterdayBitgo,
    No: 'no' as SmoothnessYesterdayBitgo,
    Unknown: 'unknown' as SmoothnessYesterdayBitgo,
  };
  export type EvacuationBitgo = 'yes' | 'no' | 'partially';
  export const EvacuationBitgo = {
    Yes: 'yes' as EvacuationBitgo,
    No: 'no' as EvacuationBitgo,
    Partially: 'partially' as EvacuationBitgo,
  };
  export type ScatteringBitgo = 'yes' | 'no' | 'partially';
  export const ScatteringBitgo = {
    Yes: 'yes' as ScatteringBitgo,
    No: 'no' as ScatteringBitgo,
    Partially: 'partially' as ScatteringBitgo,
  };
  export type AccidentCauseBitgo = 'impurity' | 'smoothness' | 'unevenness' | 'other' | 'nothing';
  export const AccidentCauseBitgo = {
    Impurity: 'impurity' as AccidentCauseBitgo,
    Smoothness: 'smoothness' as AccidentCauseBitgo,
    Unevenness: 'unevenness' as AccidentCauseBitgo,
    Other: 'other' as AccidentCauseBitgo,
    Nothing: 'nothing' as AccidentCauseBitgo,
  };
}

/**
 * KKH Unfallfragebogen
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: #{placeholderVersion}#
 * Contact: online@kkh.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ConditionalPropertyBvs } from './conditional-property';

/**
 * Reitsport
 */
export interface EquestrianSportBvs {
  /**
   * hat Reitberuf
   */
  ridingProfession: EquestrianSportBvs.RidingProfessionBvs;
  /**
   * hat Pferdebetreuungsauftrag
   */
  horseCare: boolean;
  /**
   * Reittaetigkeit vorhanden
   */
  ridingActivity: boolean;
  /**
   * Sonstige Taetigkeit
   */
  otherActivity?: string;
  /**
   * Zeit für sonstige Taetigkeit
   */
  timeOtherActivity?: string;
  returnService?: ConditionalPropertyBvs;
  /**
   * hat eine ausreichende Reitfaehigkeit
   */
  sufficientRidingAbility: boolean;
  /**
   * ein auffaelliges Pferd
   */
  noticeableHorse: boolean;
  /**
   * ein Reiten auf eigene Gefahr
   */
  ridingAtOwnRisk: boolean;
  /**
   * ein Reit-Versicherungstyp
   */
  insuranceType: EquestrianSportBvs.InsuranceTypeBvs;
  horseOwner: ConditionalPropertyBvs;
  liabilityInsurance: ConditionalPropertyBvs;
}
export namespace EquestrianSportBvs {
  export type RidingProfessionBvs = 'yes' | 'no' | 'unknown';
  export const RidingProfessionBvs = {
    Yes: 'yes' as RidingProfessionBvs,
    No: 'no' as RidingProfessionBvs,
    Unknown: 'unknown' as RidingProfessionBvs,
  };
  export type InsuranceTypeBvs =
    | 'liability_exclusion'
    | 'participation'
    | 'liability_exclusion_with_participation'
    | 'no_liability_exclusion_or_participation';
  export const InsuranceTypeBvs = {
    LiabilityExclusion: 'liability_exclusion' as InsuranceTypeBvs,
    Participation: 'participation' as InsuranceTypeBvs,
    LiabilityExclusionWithParticipation: 'liability_exclusion_with_participation' as InsuranceTypeBvs,
    NoLiabilityExclusionOrParticipation: 'no_liability_exclusion_or_participation' as InsuranceTypeBvs,
  };
}

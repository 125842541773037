/**
 * KKH Unfallfragebogen
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: #{placeholderVersion}#
 * Contact: online@kkh.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AccidentDetailsBitgo } from './accident-details';
import { DocumentBitgo } from './document';
import { ClaimForCompensationBitgo } from './claim-for-compensation';

/**
 * Unfall
 */
export interface AccidentBitgo {
  /**
   * Aktenzeichen, welches durch Einmal-Login vergeben wurde
   */
  fileNumber: string;
  /**
   * Unfallzeitpunkt
   */
  accidentDateTime: string;
  /**
   * Unfallort
   */
  accidentPlace: string;
  /**
   * Verletzung
   */
  trauma: string;
  /**
   * Koerperseite
   */
  bodySide: AccidentBitgo.BodySideBitgo;
  /**
   * Beschreibung des Unfallablaufs
   */
  accidentDescription: string;
  /**
   * Telefon
   */
  phone?: string;
  /**
   * E-mail
   */
  email?: string;
  /**
   * Dokumente
   */
  documents?: Array<DocumentBitgo>;
  accidentDetails: AccidentDetailsBitgo;
  claimForCompensation: ClaimForCompensationBitgo;
}
export namespace AccidentBitgo {
  export type BodySideBitgo = 'right' | 'left' | 'both_sides' | 'no_choice';
  export const BodySideBitgo = {
    Right: 'right' as BodySideBitgo,
    Left: 'left' as BodySideBitgo,
    BothSides: 'both_sides' as BodySideBitgo,
    NoChoice: 'no_choice' as BodySideBitgo,
  };
}

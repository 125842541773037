/**
 * KKH Unfallfragebogen
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: #{placeholderVersion}#
 * Contact: online@kkh.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ConditionalPropertyBvs } from './conditional-property';
import { NameCityBvs } from './name-city';

/**
 * Arbeitsunfall
 */
export interface WorkRelatedAccidentBvs {
  /**
   * Unfallzeitpunkt
   */
  location: WorkRelatedAccidentBvs.LocationBvs;
  school?: NameCityBvs;
  /**
   * Adresse vom JobCenter
   */
  jobCenterAddress?: string;
  /**
   * Arbeitsverhältnis zum Unfallzeitpunkt
   */
  employmentStatus?: WorkRelatedAccidentBvs.EmploymentStatusBvs;
  employer?: NameCityBvs;
  welfareOffice?: NameCityBvs;
  professionalAssociation?: ConditionalPropertyBvs;
  /**
   * anerkannter Dienstunfall vorhanden
   */
  recognizedWorkRelatedAccidentExists?: boolean;
  /**
   * Art des Vorfalls
   */
  accidentType?: WorkRelatedAccidentBvs.AccidentTypeBvs;
}
export namespace WorkRelatedAccidentBvs {
  export type LocationBvs = 'work' | 'school' | 'job_center';
  export const LocationBvs = {
    Work: 'work' as LocationBvs,
    School: 'school' as LocationBvs,
    JobCenter: 'job_center' as LocationBvs,
  };
  export type EmploymentStatusBvs = 'employed' | 'freelancer' | 'civil_servant';
  export const EmploymentStatusBvs = {
    Employed: 'employed' as EmploymentStatusBvs,
    Freelancer: 'freelancer' as EmploymentStatusBvs,
    CivilServant: 'civil_servant' as EmploymentStatusBvs,
  };
  export type AccidentTypeBvs =
    | 'traffic_accident'
    | 'criminal_assault'
    | 'accident_on_land_or_footway_or_building'
    | 'other_accident';
  export const AccidentTypeBvs = {
    TrafficAccident: 'traffic_accident' as AccidentTypeBvs,
    CriminalAssault: 'criminal_assault' as AccidentTypeBvs,
    AccidentOnLandOrFootwayOrBuilding: 'accident_on_land_or_footway_or_building' as AccidentTypeBvs,
    OtherAccident: 'other_accident' as AccidentTypeBvs,
  };
}

/**
 * KKH Unfallfragebogen
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: #{placeholderVersion}#
 * Contact: online@kkh.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { NameCityBitgo } from './name-city';
import { ConditionalPropertyBitgo } from './conditional-property';

/**
 * Arbeitsunfall
 */
export interface WorkRelatedAccidentBitgo {
  /**
   * Unfallzeitpunkt
   */
  location: WorkRelatedAccidentBitgo.LocationBitgo;
  /**
   * Ort
   */
  city: string;
  /**
   * Schule
   */
  schoolName?: string;
  /**
   * Arbeitsverhältnis zum Unfallzeitpunkt
   */
  employmentStatus?: WorkRelatedAccidentBitgo.EmploymentStatusBitgo;
  employer?: NameCityBitgo;
  welfareOffice?: NameCityBitgo;
  professionalAssociation?: ConditionalPropertyBitgo;
  /**
   * anerkannter Dienstunfall vorhanden
   */
  recognizedWorkRelatedAccidentExists?: boolean;
  /**
   * Art des Vorfalls
   */
  accidentType: WorkRelatedAccidentBitgo.AccidentTypeBitgo;
}
export namespace WorkRelatedAccidentBitgo {
  export type LocationBitgo = 'work' | 'school' | 'job_center';
  export const LocationBitgo = {
    Work: 'work' as LocationBitgo,
    School: 'school' as LocationBitgo,
    JobCenter: 'job_center' as LocationBitgo,
  };
  export type EmploymentStatusBitgo = 'employed' | 'freelancer' | 'civil_servant';
  export const EmploymentStatusBitgo = {
    Employed: 'employed' as EmploymentStatusBitgo,
    Freelancer: 'freelancer' as EmploymentStatusBitgo,
    CivilServant: 'civil_servant' as EmploymentStatusBitgo,
  };
  export type AccidentTypeBitgo =
    | 'traffic_accident'
    | 'criminal_assault'
    | 'accident_on_land_or_footway_or_building'
    | 'other_accident';
  export const AccidentTypeBitgo = {
    TrafficAccident: 'traffic_accident' as AccidentTypeBitgo,
    CriminalAssault: 'criminal_assault' as AccidentTypeBitgo,
    AccidentOnLandOrFootwayOrBuilding: 'accident_on_land_or_footway_or_building' as AccidentTypeBitgo,
    OtherAccident: 'other_accident' as AccidentTypeBitgo,
  };
}

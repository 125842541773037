/**
 * KKH Unfallfragebogen
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: #{placeholderVersion}#
 * Contact: online@kkh.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { RoadUserBitgo } from './road-user';
import { PublicTransportBitgo } from './public-transport';

/**
 * Verkehrsunfall
 */
export interface TrafficAccidentBitgo {
  /**
   * beteiligt als
   */
  involvedAs: TrafficAccidentBitgo.InvolvedAsBitgo;
  /**
   * Fahrzeughalter vorhanden
   */
  vehicleKeeperExists: boolean;
  /**
   * Eigentuemer
   */
  owner: boolean;
  /**
   * Daten des Fahrzeughalters
   */
  vehicleKeeperDetails?: string;
  /**
   * Kennzeichen
   */
  registrationNumber?: string;
  /**
   * Haftpflichtversicherung
   */
  insuranceName?: string;
  /**
   * Verkehrsmittel
   */
  transport?: string;
  roadUser?: RoadUserBitgo;
  publicTransport?: PublicTransportBitgo;
}
export namespace TrafficAccidentBitgo {
  export type InvolvedAsBitgo =
    | 'driver'
    | 'car_passenger'
    | 'public_transport_passenger'
    | 'bicyclist'
    | 'pedestrian'
    | 'driver_of_another_transport'
    | 'passenger_of_another_transport';
  export const InvolvedAsBitgo = {
    Driver: 'driver' as InvolvedAsBitgo,
    CarPassenger: 'car_passenger' as InvolvedAsBitgo,
    PublicTransportPassenger: 'public_transport_passenger' as InvolvedAsBitgo,
    Bicyclist: 'bicyclist' as InvolvedAsBitgo,
    Pedestrian: 'pedestrian' as InvolvedAsBitgo,
    DriverOfAnotherTransport: 'driver_of_another_transport' as InvolvedAsBitgo,
    PassangerOfAnotherTransport: 'passenger_of_another_transport' as InvolvedAsBitgo,
  };
}

/**
 * KKH Unfallfragebogen
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: #{placeholderVersion}#
 * Contact: online@kkh.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { NameCityBitgo } from './name-city';
import { PrivateSportBitgo } from './private-sport';
import { EquestrianSportBitgo } from './equestrian-sport';

/**
 * Sportunfall
 */
export interface SportAccidentBitgo {
  /**
   * Art des Sportunfalls
   */
  sportAccidentType: SportAccidentBitgo.SportAccidentTypeBitgo;
  /**
   * Ihre Funktion
   */
  sportAccidentFunction?: SportAccidentBitgo.SportAccidentFunctionBitgo;
  /**
   * hohes Entgelt Sportfunktion vorhanden
   */
  highPaymentSport?: boolean;
  /**
   * eigenes Reittier
   */
  ownHorse?: boolean;
  company?: NameCityBitgo;
  equestrianSport?: EquestrianSportBitgo;
  privateSport?: PrivateSportBitgo;
}
export namespace SportAccidentBitgo {
  export type SportAccidentTypeBitgo = 'private' | 'company_or_school' | 'equitation' | 'club';
  export const SportAccidentTypeBitgo = {
    Private: 'private' as SportAccidentTypeBitgo,
    CompanyOrSchool: 'company_or_school' as SportAccidentTypeBitgo,
    Equitation: 'equitation' as SportAccidentTypeBitgo,
    Club: 'club' as SportAccidentTypeBitgo,
  };
  export type SportAccidentFunctionBitgo = 'trainer' | 'player' | 'other';
  export const SportAccidentFunctionBitgo = {
    Trainer: 'trainer' as SportAccidentFunctionBitgo,
    Player: 'player' as SportAccidentFunctionBitgo,
    Other: 'other' as SportAccidentFunctionBitgo,
  };
}

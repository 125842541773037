/**
 * KKH Unfallfragebogen
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: #{placeholderVersion}#
 * Contact: online@kkh.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ClaimForCompensationBvs } from './claim-for-compensation';
import { DocumentBvs } from './document';
import { AccidentDetailsBvs } from './accident-details';

/**
 * Unfall
 */
export interface AccidentBvs {
  /**
   * Unfallzeitpunkt
   */
  accidentDateTime: string;
  /**
   * Unfallort
   */
  accidentPlace: string;
  /**
   * Verletzung
   */
  trauma: string;
  /**
   * Koerperseite
   */
  bodySide: AccidentBvs.BodySideBvs;
  /**
   * Beschreibung des Unfallablaufs
   */
  accidentDescription: string;
  /**
   * Telefon
   */
  phone?: string;
  /**
   * E-mail
   */
  email?: string;
  /**
   * Dokumente
   */
  documents?: Array<DocumentBvs>;
  accidentDetails: AccidentDetailsBvs;
  claimForCompensation?: ClaimForCompensationBvs;
}
export namespace AccidentBvs {
  export type BodySideBvs = 'right' | 'left' | 'both_sides' | 'no_choice';
  export const BodySideBvs = {
    Right: 'right' as BodySideBvs,
    Left: 'left' as BodySideBvs,
    BothSides: 'both_sides' as BodySideBvs,
    NoChoice: 'no_choice' as BodySideBvs,
  };
}

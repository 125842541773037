/**
 * KKH Unfallfragebogen
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: #{placeholderVersion}#
 * Contact: online@kkh.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DefectProductBvs } from './defect-product';
import { PrivateActivityBvs } from './private-activity';
import { ResponsiblePersonBvs } from './responsible-person';
import { PrivateLandBvs } from './private-land';
import { TrafficAccidentBvs } from './traffic-accident';
import { VoluntaryActivityBvs } from './voluntary-activity';
import { NameCityBvs } from './name-city';
import { CareBvs } from './care';
import { WorkRelatedAccidentBvs } from './work-related-accident';
import { AnimalBvs } from './animal';
import { OccupationalDiseaseBvs } from './occupational-disease';
import { HomeBuildingBvs } from './home-building';
import { SportAccidentBvs } from './sport-accident';

/**
 * Details zum Unfall
 */
export interface AccidentDetailsBvs {
  /**
   * Unfallart
   */
  accidentType?: AccidentDetailsBvs.AccidentTypeBvs;
  /**
   * Bitte geben Sie den konkreten Unfallort an
   */
  accidentLand?: AccidentDetailsBvs.AccidentLandBvs;
  privateLand?: PrivateLandBvs;
  trafficAccident?: TrafficAccidentBvs;
  sportAccident?: SportAccidentBvs;
  workRelatedAccident?: WorkRelatedAccidentBvs;
  /**
   * Bitte geben Sie an, in welchem Zusammenhang sich der Unfall ereignet hat
   */
  accidentContext?: AccidentDetailsBvs.AccidentContextBvs;
  /**
   * Auswahl Pflege
   */
  careAccidentAsCaregiver?: boolean;
  animal?: AnimalBvs;
  /**
   * verantwortliche Person bekannt
   */
  responsiblePersonKnown?: boolean;
  /**
   * verantwortliche Personen
   */
  responsiblePersons?: Array<ResponsiblePersonBvs>;
  voluntaryActivity?: VoluntaryActivityBvs;
  privateActivity?: PrivateActivityBvs;
  /**
   * beschreibung Hilfeleistung
   */
  assistanceDetails?: string;
  bloodDonationService?: NameCityBvs;
  witnessInstitute?: NameCityBvs;
  patientInstitute?: NameCityBvs;
  /**
   * Berufserkrankung vorhanden
   */
  occupationalDiseaseExists?: boolean;
  occupationalDisease?: OccupationalDiseaseBvs;
  care?: CareBvs;
  homeBuilding?: HomeBuildingBvs;
  defectProduct?: DefectProductBvs;
}
export namespace AccidentDetailsBvs {
  export type AccidentTypeBvs =
    | 'traffic_accident'
    | 'criminal_assault'
    | 'accident_on_land_or_footway_or_building'
    | 'sport_accident'
    | 'other_accident'
    | 'disease'
    | 'accident_due_defect_product';
  export const AccidentTypeBvs = {
    TrafficAccident: 'traffic_accident' as AccidentTypeBvs,
    CriminalAssault: 'criminal_assault' as AccidentTypeBvs,
    AccidentOnLandOrFootwayOrBuilding: 'accident_on_land_or_footway_or_building' as AccidentTypeBvs,
    SportAccident: 'sport_accident' as AccidentTypeBvs,
    OtherAccident: 'other_accident' as AccidentTypeBvs,
    Disease: 'disease' as AccidentTypeBvs,
    AccidentDueDefectProduct: 'accident_due_defect_product' as AccidentTypeBvs,
  };
  export type AccidentLandBvs = 'public' | 'private' | 'own';
  export const AccidentLandBvs = {
    Public: 'public' as AccidentLandBvs,
    Private: 'private' as AccidentLandBvs,
    Own: 'own' as AccidentLandBvs,
  };
  export type AccidentContextBvs =
    | 'voluntary_work'
    | 'assistance'
    | 'work_for_others'
    | 'care'
    | 'hospital'
    | 'donation'
    | 'home_building'
    | 'witness'
    | 'animal'
    | 'defect_product'
    | 'other';
  export const AccidentContextBvs = {
    VoluntaryWork: 'voluntary_work' as AccidentContextBvs,
    Assistance: 'assistance' as AccidentContextBvs,
    WorkForOthers: 'work_for_others' as AccidentContextBvs,
    Care: 'care' as AccidentContextBvs,
    Hospital: 'hospital' as AccidentContextBvs,
    Donation: 'donation' as AccidentContextBvs,
    HomeBuilding: 'home_building' as AccidentContextBvs,
    Witness: 'witness' as AccidentContextBvs,
    Animal: 'animal' as AccidentContextBvs,
    DefectProduct: 'defect_product' as AccidentContextBvs,
    Other: 'other' as AccidentContextBvs,
  };
}

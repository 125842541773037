/**
 * KKH Unfallfragebogen
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: #{placeholderVersion}#
 * Contact: online@kkh.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { NameCityBitgo } from './name-city';
import { OccupationalDiseaseBitgo } from './occupational-disease';
import { PrivateActivityBitgo } from './private-activity';
import { ResponsiblePersonBitgo } from './responsible-person';
import { DefectProductBitgo } from './defect-product';
import { CareBitgo } from './care';
import { HomeBuildingBitgo } from './home-building';
import { SportAccidentBitgo } from './sport-accident';
import { AnimalBitgo } from './animal';
import { TrafficAccidentBitgo } from './traffic-accident';
import { WorkRelatedAccidentBitgo } from './work-related-accident';
import { VoluntaryActivityBitgo } from './voluntary-activity';
import { PrivateLandBitgo } from './private-land';

/**
 * Details zum Unfall
 */
export interface AccidentDetailsBitgo {
  /**
   * Unfallart
   */
  accidentType: AccidentDetailsBitgo.AccidentTypeBitgo;
  /**
   * Bitte geben Sie den konkreten Unfallort an
   */
  accidentLand?: AccidentDetailsBitgo.AccidentLandBitgo;
  privateLand?: PrivateLandBitgo;
  trafficAccident?: TrafficAccidentBitgo;
  sportAccident?: SportAccidentBitgo;
  workRelatedAccident?: WorkRelatedAccidentBitgo;
  /**
   * Bitte geben Sie an, in welchem Zusammenhang sich der Unfall ereignet hat
   */
  accidentContext?: AccidentDetailsBitgo.AccidentContextBitgo;
  /**
   * Auswahl Pflege
   */
  careAccidentAsCaregiver?: boolean;
  animal?: AnimalBitgo;
  /**
   * verantwortliche Person bekannt
   */
  responsiblePersonKnown?: boolean;
  /**
   * verantwortliche Personen
   */
  responsiblePersons?: Array<ResponsiblePersonBitgo>;
  voluntaryActivity?: VoluntaryActivityBitgo;
  privateActivity?: PrivateActivityBitgo;
  /**
   * beschreibung Hilfeleistung
   */
  assistanceDetails?: string;
  bloodDonationService?: NameCityBitgo;
  witnessInstitute?: NameCityBitgo;
  patientInstitute?: NameCityBitgo;
  /**
   * Berufserkrankung vorhanden
   */
  occupationalDiseaseExists?: boolean;
  occupationalDisease?: OccupationalDiseaseBitgo;
  care?: CareBitgo;
  homeBuilding?: HomeBuildingBitgo;
  defectProduct?: DefectProductBitgo;
}
export namespace AccidentDetailsBitgo {
  export type AccidentTypeBitgo =
    | 'traffic_accident'
    | 'criminal_assault'
    | 'accident_on_land_or_footway_or_building'
    | 'sport_accident'
    | 'other_accident'
    | 'disease'
    | 'accident_due_defect_product';
  export const AccidentTypeBitgo = {
    TrafficAccident: 'traffic_accident' as AccidentTypeBitgo,
    CriminalAssault: 'criminal_assault' as AccidentTypeBitgo,
    AccidentOnLandOrFootwayOrBuilding: 'accident_on_land_or_footway_or_building' as AccidentTypeBitgo,
    SportAccident: 'sport_accident' as AccidentTypeBitgo,
    OtherAccident: 'other_accident' as AccidentTypeBitgo,
    Disease: 'disease' as AccidentTypeBitgo,
    AccidentDueDefectProduct: 'accident_due_defect_product' as AccidentTypeBitgo,
  };
  export type AccidentLandBitgo = 'public' | 'private' | 'own';
  export const AccidentLandBitgo = {
    Public: 'public' as AccidentLandBitgo,
    Private: 'private' as AccidentLandBitgo,
    Own: 'own' as AccidentLandBitgo,
  };
  export type AccidentContextBitgo =
    | 'voluntary_work'
    | 'assistance'
    | 'work_for_others'
    | 'care'
    | 'hospital'
    | 'donation'
    | 'home_building'
    | 'witness'
    | 'animal'
    | 'defect_product'
    | 'other';
  export const AccidentContextBitgo = {
    VoluntaryWork: 'voluntary_work' as AccidentContextBitgo,
    Assistance: 'assistance' as AccidentContextBitgo,
    WorkForOthers: 'work_for_others' as AccidentContextBitgo,
    Care: 'care' as AccidentContextBitgo,
    Hospital: 'hospital' as AccidentContextBitgo,
    Donation: 'donation' as AccidentContextBitgo,
    HomeBuilding: 'home_building' as AccidentContextBitgo,
    Witness: 'witness' as AccidentContextBitgo,
    Animal: 'animal' as AccidentContextBitgo,
    DefectProduct: 'defect_product' as AccidentContextBitgo,
    Other: 'other' as AccidentContextBitgo,
  };
}
